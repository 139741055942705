import React from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { PostHog } from "posthog-node";
import type { PosthogBootstrapDataType } from "./experiment";

if (typeof window !== "undefined" && process.env.NODE_ENV !== "test") {
	posthog.init(ENV.POSTHOG_PUBLIC_KEY, {
		api_host: "https://avnsmarketingtest.net/ph",
		ui_host: "https://eu.posthog.com",
		autocapture: false,
		capture_pageview: false,
		capture_pageleave: false,
		disable_session_recording: true,
		opt_out_capturing_by_default: true,
	});

	// disable event capturing
	posthog.opt_out_capturing();
}

type PHProviderProps = {
	children: React.ReactNode;
	bootstrapData?: PosthogBootstrapDataType;
};

export default function PHProvider({
	children,
	bootstrapData,
}: PHProviderProps) {
	const apiKey = ENV.POSTHOG_PUBLIC_KEY;

	if (typeof window !== "undefined") {
		posthog.init(apiKey, {
			api_host: "https://avnsmarketingtest.net/ph",
			advanced_disable_feature_flags: true,
			bootstrap: bootstrapData,
		});
	}

	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

export function PostHogClient() {
	const apiKey = process.env.POSTHOG_PUBLIC_KEY;
	if (apiKey) {
		const posthogClient = new PostHog(apiKey, {
			host: "https://aiven.io/ph",
		});
		return posthogClient;
	}
}
