import React from "react";
import YouTube from "react-youtube";
import type { YouTubeEvent, YouTubeProps } from "react-youtube";
import { Image } from "~/components/ui/image";
import type { ImageProps } from "~/components/ui/image";
import { Icon } from "./ui/icons";
import { useOnScreen } from "~/hooks/common";

export type Props = {
	id: string;
	children?: string;
	loading?: ImageProps["loading"];
	image?: ImageProps;
} & YouTubeProps;

export function Youtube({
	id,
	children,
	loading = "lazy",
	image,
	...rest
}: Props) {
	const [showYt, setShowYt] = React.useState(false);
	const [isThumbNailResAvailable, setIsThumbNailResAvailable] =
		React.useState(true);
	const imgRef = React.useRef<HTMLImageElement>(null);

	const ytContainerRef = React.useRef<HTMLDivElement>(null);
	const ytPlayerRef = React.useRef<YouTube>(null);
	const isVisible = useOnScreen(ytContainerRef, "-100px");

	React.useEffect(() => {
		if (imgRef.current && imgRef.current.complete) {
			handleOnLoad();
		}
	}, [imgRef]);

	React.useEffect(() => {
		const yt = ytPlayerRef.current?.internalPlayer;

		if (yt && !isVisible) {
			yt.pauseVideo();
		}
	}, [isVisible, ytPlayerRef]);

	function handleOnLoad() {
		const naturalWidth = imgRef?.current?.naturalWidth || 0;
		const isThumbNailResAvailable = naturalWidth > 120;

		if (isThumbNailResAvailable) {
			return;
		}

		setIsThumbNailResAvailable(isThumbNailResAvailable);
	}

	const handleOnReady = (e: YouTubeEvent) => {
		e.target.playVideo();
	};

	const opts: YouTubeProps["opts"] = {
		height: "100%",
		width: "100%",
		playerVars: {
			autoplay: 1,
			rel: 0,
			modestbranding: 1,
			loop: 1,
			playlist: id,
		},
	};

	return (
		<div ref={ytContainerRef}>
			{showYt ? (
				<YouTube
					ref={ytPlayerRef}
					{...rest}
					className="relative z-10 aspect-video rounded-lg bg-black"
					iframeClassName="rounded-lg"
					videoId={id}
					title={children ? children : "Youtube embedded"}
					opts={opts}
					onReady={handleOnReady}
				/>
			) : (
				<div
					className="group/player filter-dropshadow bg-secondary-50/20 relative flex aspect-video cursor-pointer items-center justify-center overflow-hidden rounded-lg before:absolute before:inset-0 before:rounded-lg before:bg-secondary-50 before:opacity-20 before:transition-opacity hover:before:opacity-30"
					onClick={() => setShowYt(true)}
				>
					<div className="absolute flex justify-center align-middle">
						<Icon
							name="play"
							width="96"
							height="96"
							color="inverse"
							className="drop-shadow-md"
						/>
						<Icon
							name="play"
							width="96"
							height="96"
							color="inverse"
							className="absolute animate-ping opacity-75 group-hover/player:animate-none"
						/>
					</div>
					{image && image?.src ? (
						<Image {...image} />
					) : (
						<Image
							src={
								isThumbNailResAvailable
									? `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
									: `https://img.youtube.com/vi/${id}/maxres1.jpg`
							}
							ref={imgRef}
							onLoad={handleOnLoad}
							alt={children ? children : "Youtube thumbnail"}
							loading={loading}
						/>
					)}
				</div>
			)}
		</div>
	);
}
