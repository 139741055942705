import { Youtube } from "~/components/youtube";
import type { Props } from "~/components/youtube";
import type { YouTubeEvent } from "react-youtube";
import { spTrackVideo } from "~/utils/tracking";

export function useYoutubeTracking(id: string) {
	const handleOnStateChange = (e: YouTubeEvent) => {
		let state: string;

		switch (e.data) {
			case -1:
				state = "unstarted";
				break;
			case 0:
				state = "ended";
				break;
			case 1:
				state = "playing";
				break;
			case 2:
				state = "paused";
				break;
			case 3:
				state = "buffering";
				break;
			case 5:
				state = "cued";
				break;
			default:
				state = "unknown";
		}

		const duration = Math.floor(e.target.getDuration());
		const currentTime = Math.floor(e.target.getCurrentTime());
		const progress = Math.floor((currentTime / duration) * 100);
		const percentagesArr = [25, 50, 75, 100];
		let progressPercentage = 0;
		let i = 0;
		while (percentagesArr[i] <= progress) {
			progressPercentage = percentagesArr[i++];
		}

		spTrackVideo({
			state: currentTime === 0 ? "start" : state,
			title: e.target.getVideoData().title,
			url: `https://www.youtube.com/watch?v=${id}`,
			duration: duration,
			currentTime: currentTime,
			percentage: progressPercentage,
		});
	};

	return { handleOnStateChange };
}

export function YoutubeWithTracking(props: Props) {
	const { handleOnStateChange } = useYoutubeTracking(props.id);

	return <Youtube onStateChange={handleOnStateChange} {...props} />;
}
