import React from "react";
import clsx from "clsx";
import type { HTMLProps } from "react";
import { Paragraph } from "./typography";

// * ================== TABLE =====================

export type TableProps = HTMLProps<HTMLTableElement> & {
	isLoading?: boolean;
	children: [
		React.ReactElement<TableHeadProps>,
		React.ReactElement<TableBodyProps>
	];
};

const tableClassNames = "w-full";

const TableContext = React.createContext<{ isLoading?: boolean } | null>(null);

export function useTableContext() {
	const context = React.useContext(TableContext);

	if (context === null) {
		throw new Error(
			"useTableContext must be used within a TableContext Provider"
		);
	}

	return context;
}

export const Table: React.FC<TableProps> & {
	Head: React.FC<TableHeadProps>;
	Cell: React.FC<TableCellProps>;
	Row: React.FC<TableRowProps>;
	Body: React.FC<TableBodyProps>;
} = ({ children, className, isLoading, ...rest }) => {
	return (
		<div className="relative w-full">
			<TableContext.Provider value={{ isLoading }}>
				<table className={clsx(tableClassNames, className)} {...rest}>
					{children}
				</table>
			</TableContext.Provider>
		</div>
	);
};

// * ================== TABLE HEAD =====================

export type TableHeadProps = HTMLProps<HTMLTableSectionElement> & {
	sticky?: boolean;
	top?: string;
	children: React.ReactNode;
};

const HeadContext = React.createContext<TableHeadProps | null>(null);

function TableHead({
	children,
	sticky,
	className,
	top = "0px",
	...rest
}: TableHeadProps) {
	// We want to use this component in Markdown where we don't have control over HTML
	// If table head children is already wrapped within tr, we don't need to do it again here
	const TableRow = React.Children.count(children) > 1 ? "tr" : React.Fragment;

	return (
		<thead className={clsx("border-y border-grey-80", className)} {...rest}>
			<TableRow>
				<HeadContext.Provider value={{ children, sticky, top }}>
					{children}
				</HeadContext.Provider>
			</TableRow>
		</thead>
	);
}

// * ================== TABLE BODY =====================
export type TableBodyProps = HTMLProps<HTMLTableSectionElement>;

function TableBody({ children, ...rest }: TableBodyProps) {
	return <tbody {...rest}>{children}</tbody>;
}

// * ================== TABLE ROW =====================

export type TableRowProps = HTMLProps<HTMLTableRowElement> & {
	children: React.ReactNode;
	active?: boolean;
	activeClasses?: string;
};

function TableRow({
	className,
	children,
	onClick,
	disabled,
	active,
	activeClasses = "text-theme-primary",
	...rest
}: TableRowProps) {
	const selectableRow = typeof onClick === "function";

	const activeClassNames = active ? activeClasses : null;

	return (
		<tr
			{...rest}
			onClick={onClick}
			className={clsx(
				"hover:bg-secondary",
				{
					"cursor-pointer": selectableRow,
					"cursor-default text-grey-40": disabled,
				},
				{ "bg-primary": !active },
				activeClassNames,
				className
			)}
		>
			{children}
		</tr>
	);
}

// * ================== TABLE CELL =====================

export type TableCellProps = HTMLProps<HTMLTableCellElement> & {
	children?: React.ReactNode;
};

const tableHeadCellClassNames = "p-4 text-left bg-primary border-stroke";
const tableCellClassNames = "p-4 border-b border-stroke align-top";

function TableCell({ children, className, ...rest }: TableCellProps) {
	const headContext = React.useContext(HeadContext);

	const { isLoading } = useTableContext();

	const skeletonCell = (
		<div
			className={clsx("h-[20px] w-[100px] animate-pulse bg-grey-10")}
		></div>
	);

	return headContext ? (
		<th
			{...rest}
			style={{ top: headContext.top }}
			className={clsx(
				tableHeadCellClassNames,
				{
					sticky: headContext.sticky,
				},
				className
			)}
		>
			{(children || children === 0) && isLoading ? (
				skeletonCell
			) : (
				<Paragraph
					as="span"
					size="body-small"
					fontWeight="font-medium"
					color="current"
				>
					{children}
				</Paragraph>
			)}
		</th>
	) : (
		<td {...rest} className={clsx(tableCellClassNames, className)}>
			{(children || children === 0) && isLoading ? (
				skeletonCell
			) : (
				<Paragraph as="span" size="body-small" color="current">
					{children}
				</Paragraph>
			)}
		</td>
	);
}

Table.Head = TableHead;
Table.Cell = TableCell;
Table.Row = TableRow;
Table.Body = TableBody;
